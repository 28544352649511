<template>
  <div>
    <div>
      <el-button size="mini" type="primary" @click="handleComeBack" v-waves>返回列表</el-button>
      <el-divider></el-divider>
      <el-descriptions title="工单详情" border>
        <el-descriptions-item label="工单号">{{scanOrderInfo.id}}</el-descriptions-item>
        <el-descriptions-item label="ODM制造商">{{scanOrderInfo.manufacturer.name}}</el-descriptions-item>
        <el-descriptions-item label="创单时间">{{scanOrderInfo.createTime}}</el-descriptions-item>
        <el-descriptions-item label="生产线">{{scanOrderInfo.scanLine}}</el-descriptions-item>
        <el-descriptions-item label="产品型号">{{scanOrderInfo.model.name}}</el-descriptions-item>
        <el-descriptions-item label="物料代码">{{scanOrderInfo.model.materialCode}}</el-descriptions-item>
        <el-descriptions-item label="实际扫码数量">{{scanOrderInfo.labelNumber}}</el-descriptions-item>
        <el-descriptions-item label="完成时间">{{scanOrderInfo.finishTime}}</el-descriptions-item>
        <el-descriptions-item label="扫码用时">
          <template #default>
            {{scanOrderInfo.scanTime === null || scanOrderInfo.scanTime === undefined ? '' : scanOrderInfo.scanTime + ' 秒'}}
          </template>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div v-show="isSelf" style="margin-top: 20px" v-if="scanOrderInfo.scanStatus.name !== 'FINISH'">
      <el-row class="el-scan">
        <el-col :span="4">
          <div class="el-scan_produce">
            <div class="el-scan_count">{{scanCount}}</div>
            <div class="el-scan_count__desc">已生产数量</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="el-scan_state">
            <el-image v-show="scanResult === false" class="el-scan_result" :src="scanSuccess" alt="success"></el-image>
            <el-image v-show="scanResult === true" class="el-scan_result" :src="scanError" alt="error"></el-image>
			<el-button v-show="scanResult === true" style="width:100%;height:65px;border:none;margin-top:88px;font-weight:100;position:absolute;overflow: hidden;
  white-space: normal;"><font style="font-size:10px;display:flex;color:red;justify-content: center;">{{scanFaultReson}}</font></el-button>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="el-scan_start">
            <el-input v-model="label" readonly style="width: 240px;"></el-input>
            <el-button v-show="scanState === 0" icon="el-icon-video-play" style="margin-left: 5px" size="mini" type="primary" @click="handleScanStart" v-waves>开始扫码</el-button>
            <el-button v-show="scanState === 1" icon="el-icon-video-pause" style="margin-left: 5px" size="mini" type="warning" @click="handleScanStop" v-waves>暂停扫码</el-button>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="float: right">
            <el-button size="mini" type="primary" plain @click="handleScanRecord" v-waves>扫码记录</el-button>
            <div class="el-scan_end">
              <el-button v-show="scanState === 1" icon="el-icon-switch-button" type="danger" @click="handleScanEnd" v-waves>结束扫码</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
<!--    扫码失败-->
	<div>
		<el-dialog
		  v-model="scanErrorDialog"
		  width="20%"
		  center>
		  <p style="width:100%;margin-top:-30px;"><i class="el-icon-close"  style="color:red;font-size:100px;font-weight: 900;display: flex;justify-content: center;"></i></p>
		  <p style="width:100%;margin-top:20px;"><font style="font-size:18px;display:flex;justify-content: center;">{{scanFaultReson}}</font></p>
		  <p style="width:100%;margin-top:40px;"><span style="display:flex;justify-content: center;">
			<el-button type="primary" @click="changeScan">继续生产</el-button>
		  </span>
		  </p>
		</el-dialog>
		<el-dialog
		  title="完成生产"
		  v-model="showEnd"
		  width="20%"
		  center>
		  <p style="width:100%;"><font style="font-size:18px;display:flex;justify-content: center;">"结束扫码"后,当前工单即可"完成生产"后续产
品将无法通过当前工单生产.
</font></p>
		  <p style="width:100%;margin-top:40px;"><span style="display:flex;justify-content: center;">
		    <el-button @click="changeEnd">取 消</el-button>
			<el-button type="primary" @click="handleScanEnd">完成生产</el-button>
		  </span>
		  </p>
		</el-dialog>
		<!--    结束扫码-->
		<!-- <el-dialog></el-dialog> -->
	</div>
  </div>
</template>

<script>
import scanSuccess from "@/assets/img/scanSuccess.png";
import scanError from "@/assets/img/scanError.png";
import scanInfoApi from "@/apis/scan/scanInfo";
import { saveAs } from 'file-saver'
import {ElNotification} from "element-plus";
import Speech from 'speak-tts'
export default {
  data() {
    return {
      scanSuccess,
      scanError,
      scanId: '',
      scanOrderInfo: {
        id: '',
        manufacturer: {name: ''},
        createTime: '',
        scanLine: '',
        model: {name: '',materialCode: ''},
        labelNumber: '',
        finishTime: '',
        scanTime: '',
        scanStatus: {name: 'FINISH'}
      },
      scanResult: '',
      scanErrorDialog: false,
      scanState: 0,
      scanCount: 0,
	  showEnd:false,
	  scanFaultReson:'录入失败',
      label: '',
	  port:null,
	  reader:null,
	  serialName:'',
	  serialState:false,
	  timer:null,
      isSelf: true,
	  speech: null,
    }
  },
  watch:{
	scanErrorDialog(){
	  if(this.scanErrorDialog){
		 this.handleSpeech();
	  }
	}
  },
  mounted() {
      this.speechInit();
    },
  created() {
    this.scanId = this.$route.query.id
    this.getInfo()
    if ("serial" in navigator) {

	  } else {
		  ElNotification({
			  title: '浏览器版本低',
			  message: '您的浏览器版本太低，可能不支持浏览器串口的使用',
			  type: 'error',
			  duration: 20000
		  })
    }
  },
  methods: {
	  speechInit() {
		this.speech = new Speech();
		this.speech.setLanguage("zh-CN");
		this.speech.init().then(() => {
			console.log("语音初始成功");
		});
	  },
	  handleSpeech() {
		this.speech.speak({ text: this.scanFaultReson }).then(() => {
			  console.log("播报成功");
			});
	  },
	changeScan(){
	  if(this.scanErrorDialog){
		  this.scanErrorDialog = false;
	  }else{
      this.scanErrorDialog = true
	  }
	},
	changeEnd(){
		if(this.showEnd){
			this.showEnd = false;
		}else{
			this.showEnd = true;
		}
	},
    getInfo() {
      scanInfoApi.getScanInfo(this.scanId).then(res => {
        this.scanOrderInfo = res.data
        this.scanCount = this?.scanOrderInfo?.labelNumber ?? 0
        // this.isSelf = res.data.scanner.account === this.$store.getters.account;
        this.isSelf = this.$store.getters.accountType.name === 'SCANNER' || res.data.scanner.account === this.$store.getters.account;
      })
    },
    handleComeBack() {
	  this.closeOpenPort()
      this.$router.push({path: '/produce/scan/list'})
    },
    // 扫码录入
    handleInput() {
      const param = {
        id: this.scanId,
        label: this.label
      }
	  //校验重复扫码
	  let localCode = localStorage.getItem("codes_"+this.scanId)
	  if(localCode != null){
		  localCode = JSON.parse(localCode);
		if(localCode.indexOf(this.label) >= 0){
		   this.scanErrorDialog = true
		   this.scanFaultReson = this.label+"重复扫码";
		   this.scanResult = true
		   return ;
		}
	  }else{
		 localCode = [];
	  }
	  // 校验条码范围
      let scanMaterialCode = this.label.substring(3,10)
      let scanManufacturerCode = this.label.substring(11,15).toUpperCase()

      let categoryCode = this.scanOrderInfo.model?.category?.categoryCode
      let materialCode = this.scanOrderInfo.model.materialCode.substring(2)
      let manufacturerCode = this.scanOrderInfo.manufacturer.code.toUpperCase()
      if (scanManufacturerCode !== manufacturerCode) {
        // 条码不在制造商中
        this.scanErrorDialog = true
        this.scanFaultReson = this.label+"不是该制造商条码";
        this.scanResult = true
        return ;
      }
      if (scanMaterialCode !== materialCode && scanMaterialCode !== categoryCode) {
        // 条码不在物料中
        this.scanErrorDialog = true
        this.scanFaultReson = this.label+"不是该型号条码";
        this.scanResult = true
        return ;
      }
      scanInfoApi.saveScan(param).then(res => {
        if (res.data === undefined || res.data === null) {
          this.scanResult = true
          this.scanErrorDialog = true
		  this.scanFaultReson = res.msg
        }else {
		  localCode.push(this.label);
		  localStorage.setItem("codes_"+this.scanId,JSON.stringify(localCode));
          this.scanResult = false
          this.scanErrorDialog = false
		  this.scanCount += 1;
        }
      }).catch(err => {
        this.scanResult = true
        this.scanErrorDialog = true
		this.scanFaultReson = err.message
      })
    },
    handleScanRecord() {
      const param = {id: this.scanId}
      scanInfoApi.exportScanRecord(param).then(res => {
        const excelName = '扫码记录.xlsx'
        saveAs(res.data,excelName)
      }).catch(err => {
        this.$message.error('导出失败')
      })
    },
    handleScanStart() {
      this.changeScanState('START')
	  this.chooseSerial()
    },
    handleScanStop() {
      this.changeScanState('STOP')
	  this.closeSerial()
    },
    handleScanEnd() {
      this.changeScanState('FINISH')
	  this.showEnd = false;
    },
    changeScanState(scanStatus) {
      const data = {
        id: this.scanId,
        scanStatus: scanStatus
      }
      scanInfoApi.changeScanState(data).then(res => {
        if (res.data) {
          if (scanStatus === 'START') {
            this.scanState = 1
			// this.scanResult = false
          }else if (scanStatus === 'STOP') {
            this.scanState = 0
			// this.scanResult = true
          }else if (scanStatus === 'FINISH') {
            this.handleComeBack()
          }
        }
      }).catch(error => {
        // this.$message.error('操作失败')
      })
    },
	async chooseSerial() {
            this.closeOpenPort()
			try {
				this.port = await navigator.serial.requestPort()
				if(this.port){
				  await this.port.open({ baudRate: 115200, dataBits: 8, stopBits: 1 })
				  if(this.port.open){
					  this.serialState = true;
					  var that = this;
					  this.timer = setInterval(function(){
						 if(that.port != null && that.port.readable.locked){
							 this.serialState = true;
							 console.log("连接正常");
						 }else{
							 this.serialState = false;
							 console.log("连接断开");
						 }
					  },5000)
				  }
				  this.reader = this.port.readable.getReader();
				  var newStr = "";
				  while (true) {
					const { value, done } = await this.reader.read();
					if (done) {
					  // 允许稍后关闭串口
					  this.reader.releaseLock();
					  break;
					}
					var str = "";
					for(var i = 0;i<value.length;i++){
					   var temp = value[i].toString(16);
					   if(temp.length == 1){
						   temp = "0"+temp;
					   }
					   str += temp;
					}
					//处理数据
					var getstr = this.hexCharCodeToStr(str);//数据Uint8Array类型
					if(newStr != ""){
						getstr = newStr + getstr;
					}
					if(getstr.substr(getstr.length-1).charCodeAt(0) == 0 || getstr.substr(getstr.length-1).charCodeAt(0) == 10){
						let len = 1;
						if(getstr.substr(getstr.length-1).charCodeAt(0) == 10){
							len = 2;
						}
						let snStr = getstr.substr(0,getstr.length-2);
						if(snStr.length > 24){
							if(snStr.indexOf("sn") > 0){
							   snStr = snStr.substr(snStr.indexOf("sn")+3,24);
							}
						}
						this.label = snStr;
						console.log("---------------"+snStr);
						this.handleInput()
						newStr = "";
					}else{
						newStr = getstr;
					}

				  }
				}
			} catch (e) {
				console.log(e.message);
				console.log('选择串口设备失败');
			}
		  },
		 async closeSerial(){
			if(this.port){
				this.reader.releaseLock();
				await this.port.close();
				this.port = null;
				this.serialState = false;
			}
		  },
       hexCharCodeToStr(hexCharCodeStr) {
	  	var trimedStr = hexCharCodeStr.trim();
	  	var rawStr =
	  		trimedStr.substr(0, 2).toLowerCase() === "0x" ?
	  		trimedStr.substr(2) :
	  		trimedStr;
	  	var len = rawStr.length;
	  	if(len % 2 !== 0) {
	        throw new Error('Illegal Format ASCII Code!')
	  	}
	  	var curCharCode;
	  	var resultStr = [];
	  	for (var i = 0; i < len; i = i + 2) {
	  			curCharCode = parseInt(rawStr.substr(i, 2), 16); // ASCII Code Value
	  			if(curCharCode == 0){
	  			  resultStr.push("");
	  			}else if(curCharCode == 13){
	  			  resultStr.push("\r\n");
	  			}else{
	  			  resultStr.push(curCharCode);
	  			}
	  	}
	  	return this.readUTF(resultStr);
	  },
	  readUTF(arr) {
	  	if (typeof arr === 'string') {
	  		return arr;
	  	}
	  	let UTF = '', _arr = arr;
	  	for (let i = 0,len = _arr.length; i < len; i++) {
	  		let one = _arr[i].toString(2), v = one.match(/^1+?(?=0)/);
	  		if (v && one.length == 8) {
	  			let bytesLength = v[0].length;
	  			let store = _arr[i].toString(2).slice(7 - bytesLength);
	  			for (let st = 1; st < bytesLength; st++) {
	  				store += _arr[st + i].toString(2).slice(2)
	  			}
	  			UTF += String.fromCharCode(parseInt(store, 2));
	  			i += bytesLength - 1
	  		} else {
	  			UTF += String.fromCharCode(_arr[i])
	  		}
	  	}
	  	return UTF
	  },
	 closeOpenPort(){
	   if(this.port){
	   		   console.log('销毁open--------');
	     		this.reader.releaseLock();
	     		this.port.close();
	     		this.port = null;
	     		this.serialState = false;
	    }
	 }
  },
    destroyed() {
      this.closeOpenPort()
    }
}
</script>

<style scoped lang="scss">
.el-scan {
  height: 200px;
  border: solid 1px gainsboro;
  border-radius: 8px;
  padding: 20px 10px;
  background-color: #ffffff;
}
.el-scan_produce {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
}
.el-scan_count {
  font-size: 30px;
  color: #c71616;
  font-weight: 600;
  text-align:center;
}
.el-scan_count__desc {
  margin-top: 10px;
  color: #a7a9a9;
  font-size: 14px;
  font-weight: 600;
  text-align:center;
}
.el-scan_state {
  border: solid 1px gainsboro;
  height: 158px;
  width: 158px;
  position: relative;
}
.el-scan_result {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.el-scan_start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.el-scan_end {
  position: absolute;
  top: 50%;
  transform: translate(-30%, -50%);;
}
</style>
